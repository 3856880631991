<template>
  <div class="huimo">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_life/orange/top.png"
    />
    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      /> 
        <img
     class="submit" @click="onSubmit"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_life/orange/submit.png"
    />
      <div class="checked"  @click="checked = !checked">
        <img
          class="checked-icon"
          :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_aiJiSheng/audiovisualSelection/${
            checked ? 'checked' : 'unchecked'
          }.png`"
        />
        <span>我已阅读并同意</span>
        <span class="protocol-text" @click.stop="visibility = true">《服务协议》</span>
      </div>
    </div>
    <!-- 服务协议 -->
    <van-popup v-model="visibility" class="dialog-wrap">
      <div class="dialog-content">
        <div class="dialog-title">服务协议</div>
        <div class="dialog-text">{{ huiMoDialogContent }}</div>
        <img
          class="confirm-button"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/confirm.png"
          @click="visibility = false"
        />
      </div>
    </van-popup>
    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_life/orange/card.png"
    />
    <img
      class="card info"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_life/orange/info.png"
    />
    <img
      class="card2"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_life/orange/card2.png"
    />
     
  </div>
</template>

<script>
import { huiMoDialogContent } from "./index.js";
// import { alipayPay } from "@/api/tao.js";

export default {
  data() {
    return {
      form: {
        phone_number: "",
      },
      checked: false,
      visibility: false,
      huiMoDialogContent,
    };
  },
  methods: {
    onSubmit() {
      // const { phone_number } = this.form;
      // if (!phone_number) return this.$toast("请输入手机号码");
      // let filterPhone = /^1[3456789]\d{9}$/;
      // if (!filterPhone.test(phone_number))
      //   return this.$toast("手机号码有误请重新输入");
      // if (!this.checked)
      //   return this.$toast("请阅读并同意《服务协议》");
      // alipayPay({
      //   phone_number,
      //   item_name: "视听甄选会员",
      // }).then((res) => {
      //   if (res.code != 0) {
      //     this.$toast.fail(res.message);
      //   } else {
      //     location.href = res.sign_str
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
.huimo {
  background-color: #ffd0b6;
  .img {
    width: 100%;
  }

  .form {
    // position: absolute;
    // top: 978px;
    // left: 63px;
    // right: 63px;
    padding: 0 63px 53px;
    ::v-deep .van-cell {
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 40px;
      // width: 624px;
      height: 90px;
      background-color: #ffffff;
      border-radius: 45px;
      border: solid 1px #ffbe90;

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        // color: #781903;
        font-size: 30px;
        font-weight: normal;
        color: #4d2f1c;
        &::-webkit-input-placeholder {
          font-size: 30px;
          color: #c8c9cc;
        }
      }
    }

    .submit {
      padding-top: 20px;
      width: 100%;
    }

    .checked {
      font-size: 24px;
      color: #6e482c;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      line-height: 24px;
      padding-top: 26px;

      &-icon {
        width: 24px;
	      height: 24px;
        margin-right: 8px;
      }

      .protocol-text {
        color: #ff5d00;
      }
    }
  }

  

  .card, .info {
    display: block;
    margin: 0 auto 37px;
    width: 721px;
  }

  .card2 {
    width: 100%;
  }

  .info {
    width: 680px;
  }

  .dialog-wrap {
    width: 630px;
    height: 70vh;
    background: linear-gradient(151deg, #ffffff 0%, #ffdfd9 100%);
    border-radius: 24px;
    .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 37px;
      height: 100%;
      box-sizing: border-box;
      .dialog-title {
        font-weight: 500;
        font-size: 34px;
        color: #000000;
        margin-bottom: 30px;
        flex: none;
      }
      .dialog-text {
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        white-space: pre-line;
        overflow: auto;
        flex: 1;
      }
      .confirm-button {
        width: 510px;
        height: 90px;
        margin-top: 40px;
        flex: none;
      }
    }
  }
}
</style>